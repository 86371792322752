@import url('https://fonts.googleapis.com/css2?family=Tiny5&display=swap');


[type='number']:focus {
  outline: none !important;
  box-shadow: none !important;
  border-color: transparent !important;
}

 
.custom-skeleton {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.skeleton-header {
  width: 40%;
  height: 20px;
  background: linear-gradient(90deg, #f0f0f0, #e0e0e0, #f0f0f0);
  border-radius: 4px;
  background-size: 200% 100%;
}

.skeleton-paragraph {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.skeleton-line {
  width: 100%;
  height: 14px;
  background: linear-gradient(90deg, #f0f0f0, #e0e0e0, #f0f0f0);
  border-radius: 4px;
  background-size: 200% 100%;
}

.active .skeleton-header,
.active .skeleton-line {
  animation: skeleton-loading 1.4s ease infinite;
}

@keyframes skeleton-loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
/* 

@keyframes skeleton-loading {
  0% {
    background-position: 100% 50%  !important;;
  }
  100% {
    background-position: 0 50%  !important;;
  }
} */



.ant-message .ant-message-notice-content{
  background: black !important;
  /* padding: 0 !important; */
  border: 1px solid #282c31;
  box-shadow: 0 6px 65px 0 rgba(209,150,255,.17) !important;

  }



.ant-messagex .ant-message-notice-content{
  background: black !important;
  padding: 0 !important;
  border: 1px solid #282c31;
  box-shadow: 0 6px 65px 0 rgba(209,150,255,.17) !important;

  }


.ant-message{
width: -moz-max-content;
width: max-content;
margin: auto;
/* box-shadow: 0 6px 65px 0 rgba(209,150,255,.17) !important; */
}

:root {
  --font-inter: 'Inter', sans-serif;
  --font-roboto-mono: 'Roboto Mono', monospace;
  --font-truculenta: 'Truculenta', sans-serif;
  --font-kode: 'Kode Mono', sans-serif;
  --font-tiny: "Tiny5", sans-serif;

}




.ant-message .ant-message-notice {
  background-color: #1f1f1f; /* Set your desired background color */
  color: #ffffff; /* Set text color for better contrast */
  border-radius: 8px; /* Optional: Rounded corners */
}

.ant-message-container {
  padding: 16px; /* Adjust padding as needed */
}


.contest-card{
    font-size: 24px;
    color: #8F8F8F;
    font-family: var(--font-family-secondary);
}

.bottom-card{
  font-size: 25px;
  font-family: var(--font-family-secondary);
}

.border-antc{
  border: 1px solid rgba(212,228,254,.191)
}


.contests-group-wrap {
  justify-content: flex-start;
  gap: 24px;
  margin-bottom: 48px;
}
.contests-group-wrap, .contests-group-name, .contests-group-desc {
  width: 100%;
  max-width: 1264px;
  margin-left: auto;
  margin-right: auto;
  justify-content: flex-start;
  padding-left: 16px;
  padding-right: 16px;
  box-sizing: border-box;
  align-items: flex-start;
}
.contests-group-wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 18px;
  justify-content: center;
  position: relative;
}


contests-group-block[data-index="0"] .contests-group-card {
  flex: 0 0 33.3%;
  max-width: calc(33.3% - 16px);
}

.contests-group-card {
  flex: 0 0 25%;
  max-width: calc(25% - 18px);
  align-items: flex-end;
  display: flex;
}

.font-inter {
  font-family: var(--font-inter);
}

.font-roboto-mono {
  font-family: var(--font-roboto-mono);
}

.font-truculenta {
  font-family: var(--font-truculenta);
}

.font-kode {
  font-family: var(--font-kode-mono);
}

*,
*::before,
*::after {
  box-sizing: border-box;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, avenir next, avenir,
    segoe ui, helvetica neue, helvetica, Ubuntu, noto, arial, sans-serif;
}

* {
  margin: 0;
}

body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

input,
button,
textarea,
select {
  font: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

h1 {
  padding: 8px;
}

.text-center {
  text-align: center;
}

.container {
  display: flex;
  flex-direction: column;
}

.content-top {
  flex: 1;
}

.content-bottom {
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.full-viewport-height {
  height: 100vh;
}

@supports (height: 100dvh) {
  .full-viewport-height {
    height: 100dvh;
  }
}

.card-border {
  border: 1px solid rgba(212, 228, 254, 0.191);
}

.divider {
  background-color: #282828;
  border-color: #282828;

}

.mask-motion-enter-active, .mask-motion-appear-active, .mask-motion-leave-active {
  transition: all 0.3s;
}

.mask-motion-enter, .mask-motion-appear {
  opacity: 0;
}

.mask-motion-enter-active, .mask-motion-appear-active, .mask-motion-leave {
  opacity: 1;
}

.mask-motion-leave-active {
  opacity: 0;
}

.panel-motion-top-enter-start, .panel-motion-top-appear-start, .panel-motion-top-leave-start {
  transition: none !important;
}

.panel-motion-top-enter-active, .panel-motion-top-appear-active, .panel-motion-top-leave-active {
  transition: all 0.3s;
}

.panel-motion-top-enter, .panel-motion-top-appear {
  transform: translateY(-100%);
}

.panel-motion-top-enter-active, .panel-motion-top-appear-active, .panel-motion-top-leave {
  transform: translateY(0);
}

.panel-motion-top-leave-active {
  transform: translateY(-100%) !important;
}

.mask-motion-enter-active,.mask-motion-appear-active,.mask-motion-leave-active {
  transition: all .3s
}

.mask-motion-enter,.mask-motion-appear {
  opacity: 0
}

.mask-motion-enter-active,.mask-motion-appear-active,.mask-motion-leave {
  opacity: 1
}

.mask-motion-leave-active {
  opacity: 0
}

.panel-motion-left-enter-start,.panel-motion-left-appear-start,.panel-motion-left-leave-start {
  transition: none!important
}

.panel-motion-left-enter-active,.panel-motion-left-appear-active,.panel-motion-left-leave-active {
  transition: all .3s
}

.panel-motion-left-enter,.panel-motion-left-appear {
  transform: translate(-100%)
}

.panel-motion-left-enter-active,.panel-motion-left-appear-active,.panel-motion-left-leave {
  transform: translate(0)
}

.panel-motion-left-leave-active {
  transform: translate(-100%)!important
}

.panel-motion-right-enter-start,.panel-motion-right-appear-start,.panel-motion-right-leave-start {
  transition: none!important
}

.panel-motion-right-enter-active,.panel-motion-right-appear-active,.panel-motion-right-leave-active {
  transition: all .3s
}

.panel-motion-right-enter,.panel-motion-right-appear {
  transform: translate(100%)
}

.panel-motion-right-enter-active,.panel-motion-right-appear-active,.panel-motion-right-leave {
  transform: translate(0)
}

.panel-motion-right-leave-active {
  transform: translate(100%)!important
}

.rc-drawer {
  inset: 0;
  position: fixed;
  z-index: 30;
  pointer-events: none
}

.rc-drawer-inline {
  position: absolute
}

.rc-drawer-mask {
  inset: 0;
  position: absolute;
  z-index: 30;
  background: rgba(0,0,0,.5);
  pointer-events: auto
}

.rc-drawer-content-wrapper {
  position: absolute;
  z-index: 30;
  width: 100%;
  overflow: hidden;
  transition: transform .3s
}

.rc-drawer-content-wrapper-hidden {
  display: none
}

.rc-drawer-left .rc-drawer-content-wrapper {
  top: 0;
  bottom: 0;
  left: 0
}

.rc-drawer-right .rc-drawer-content-wrapper {
  top: 0;
  right: 0;
  bottom: 0
}

.rc-drawer-content {
  width: 100%;
  height: 100%;
  overflow: auto;
  background: #050505;
  pointer-events: auto
}

.drawer .ant-menu-inline,.drawer .ant-menu-vertical {
  border-right: none
}

.drawer-content {
  padding-top: 40px
}

.drawer-left .ant-menu-inline .ant-menu-item:after,.drawer-left .ant-menu-vertical .ant-menu-item:after {
  left: 0;
  right: auto
}

.drawer-wrapper .drawer {
  animation: alpha-to .3s ease-out .3s;
  animation-fill-mode: forwards;
  opacity: 0
}

@keyframes alpha-to {
  to {
      opacity: 1;
      left: 0
  }
}

.parent-demo {
  position: relative;
  overflow: hidden
}

.parent-demo .drawer {
  position: absolute
}



.card-border {
  border: 1px solid rgba(212, 228, 254, .191);
}

.pb-1 {
  padding-bottom: .25rem;
}
.bg-cardBlack {
  --tw-bg-opacity: 1;
  background-color: rgb(5 5 5 / var(--tw-bg-opacity));
}

.shimmer-text {
  position: relative;
  overflow: hidden;
  display: inline-block;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.2));
  background-size: 200% 100%;
  animation: shimmer 2s infinite;
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

@keyframes chevrons-move {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  50% {
    transform: translateX(10px);
    opacity: 0;
  }
  100% {
    transform: translateX(-10px);
    opacity: 1;
  }
}

.chevrons-right-icon {
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.group-hover:animate-chevrons-move {
  animation: chevrons-move 1s infinite;
}



/* 
@keyframes shimmer {
  0% {
    background-position: -200%;
  }
  100% {
    background-position: 200%;
  }
}

.shimmer {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.2) 20%,
    rgba(255, 255, 255, 0.4) 60%,
    rgba(255, 255, 255, 0)
  );
  background-size: 200%;
  animation: shimmer 2s infinite;
} */


.cta-button {

  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.cta-button:hover {
  transform: translateY(-3px); /* Lift effect */
}

.cta-button svg {
  margin-left: 0.5rem;
  transition: transform 0.3s ease;
}

.cta-button:hover svg {
  transform: translateX(5px); /* Slide arrow on hover */
}

.card-shadow{
  box-shadow: 0 6px 65px 0 rgba(121, 99, 255, .17);
}




.loading-shimmer {
  text-fill-color: grey;
  -webkit-text-fill-color: grey;
  animation-delay: 0.5s;
  animation-duration: 5s;
  color: white;
  animation-iteration-count: infinite;
  animation-name: loading-shimmer;
  background: linear-gradient(90deg, #d1d5db, #1f2937, #d1d5db);
  background-clip: text;
  -webkit-background-clip: text;
  background-repeat: no-repeat;
  background-size: 200% 100%;
  display: inline-block;
}


@keyframes loading-shimmer {
    0% {
        background-position: -100% top
    }

    to {
        background-position: 250% top
    }
}




#follow-button,#twitter-widget-0{
  border-radius: 2px;
}


.colony__card {
  margin: auto;
  min-width: 100%;
  font-size: 9px;
  text-transform: uppercase;
  padding: 5px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  position: relative;
  background: #000;
}
.card__img {
  grid-area: card-img;
  background-size: cover;
  background-position: 50% 50%;
  padding: 1rem;
  max-width: 100%;
  border-radius: 6px 6px 0 0;
}
.card__title {
  padding: 0.5rem 3px;
  grid-area: card-title;
  margin: 0;
}
.card__meta {
  grid-area: card-meta;
  padding: 0.5rem 3px;
  text-align: right;
}
.card__subtitle {
  grid-area: card-subtitle;
  margin: 0;
  padding: 10px 3px;
}
.card__description {
  grid-area: card-desc;
  margin: 0;
  border-radius: 0 0 6px 6px;
  padding: 0 3px;
}
.parallax-card {
  margin: auto;
  border-radius: 20px;
  transform-style: preserve-3d;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  position: relative;
  width: 100%;
  /* min-width: 345px; */
    
  height: 100%;
  cursor: pointer;
}
.parallax-card .parallax-card-container {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  transition: all 0.2s ease-out;
}
.parallax-card .parallax-card-shadow {
  position: absolute;
  top: 5%;
  left: 5%;
  width: 90%;
  height: 90%;
  transition: all 0.2s ease-out;
  box-shadow: 0 8px 30px rgba(14, 21, 47, 0.6);
  z-index: 0;
}
.parallax-card .parallax-card-layers {
  position: relative;
  width: 100%;
  height: 100%;
  border: none;
  border-radius:8px;
  overflow: hidden;
  transform-style: preserve-3d;
  z-index: 2;
}
.parallax-card .parallax-card-rendered-layer {
  position: absolute;
  width: 104%;
  height: 104%;
  top: -2%;
  left: -2%;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-size: cover;
  transition: all 0.1s ease-out;
  z-index: 4;
}
.parallax-card .parallax-card-shine {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 20px;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0) 60%);
  z-index: 8;
}
.parallax-card:hover {
  z-index: 9;
}
.parallax-card:hover .parallax-card-shadow {
  box-shadow: 0 45px 100px rgba(14, 21, 47, 0.4), 0 16px 40px rgba(14, 21, 47, 0.4);
}

iframe {
  color-scheme: dark;
}


html[data-theme='light'] {
  background: #000 !important ;
}
html[data-theme='dark'] {
  background:  #000 !important ;
}


.modal-open {
  overflow: hidden;
}

/* Ensure the modal content is scrollable */
.modal-content {
  max-height: 100vh;
  overflow-y: auto;
}



.join-animation::before {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(to right, rgba(245, 158, 11, 0), rgba(245, 158, 11, 0.4), rgba(245, 158, 11, 0));
  background-size: 1000px 100%;
  animation: shimmer 7s infinite linear;
  z-index: -2;
  border-radius: 999px;
}
@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
.intro-button{
  color: #fefe61;
  border: 1px solid #f59e0b;
  background-color: rgba(120, 53, 15, .5);
  opacity: 1;
  z-index: 1;
}



.text-decorated {
background: linear-gradient(90deg, #d3a86c, #91c8ca 22%, #9fe0d1 58%, #d3a86c);
-webkit-background-clip: text;
background-clip: text;
color: transparent;
padding-bottom: .1em;
}

@keyframes heartbeat {
  0%, 100% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.7;
  }
}

.animate-heartbeat {
  animation: heartbeat 1s infinite ease-in-out;
}

.react-datepicker-popper {
    z-index: 1090;
}


.react-datepicker {
    -webkit-backdrop-filter: blur(12px) saturate(190%) contrast(50%) brightness(130%);
            backdrop-filter: blur(12px) saturate(190%) contrast(50%) brightness(130%);
    overflow: hidden;
    border-radius: 0.75rem !important;
    border-style: none;
    background-color: rgb(255 255 255 / 0.5);
    font-family: Inter, sans-serif;
    --tw-text-opacity: 1;
    color: rgb(55 65 81 / var(--tw-text-opacity));
    --tw-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
    --tw-shadow-colored: 0px 3px 12px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    --tw-shadow-color: rgb(0 0 0 / 0.3);
    --tw-shadow: var(--tw-shadow-colored);
}


:is(.dark .react-datepicker) {
    background-color: rgb(0 0 0 / 0.5);
    --tw-text-opacity: 1;
    color: rgb(226 232 240 / var(--tw-text-opacity));
}

html[class='dark'] .react-datepicker {
    -webkit-backdrop-filter: blur(10px) saturate(190%) contrast(70%) brightness(80%);
            backdrop-filter: blur(10px) saturate(190%) contrast(70%) brightness(80%);
}

.react-datepicker__month-container {
    overflow: hidden;
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
    background-color: transparent !important;
}

.react-datepicker__header {
    height: 64px;
    border-color: rgb(17 24 39 / 0.05);
    background-color: transparent !important;
}

:is(.dark .react-datepicker__header) {
    border-color: rgb(226 232 240 / 0.05);
}

.react-datepicker__header--time {
    height: 64px;
    overflow: hidden;
    border-top-left-radius: 0.75rem !important;
    border-top-right-radius: 0.75rem !important;
    background-color: transparent !important;
}

.react-datepicker__time-container {
    border-color: rgb(17 24 39 / 0.05);
    background-color: transparent !important;
}

:is(.dark .react-datepicker__time-container) {
    border-color: rgb(226 232 240 / 0.05);
}

.react-datepicker__time {
    overflow: hidden;
    border-bottom-right-radius: 0.75rem !important;
    border-bottom-left-radius: 0.75rem !important;
    background-color: transparent !important;
}

.react-datepicker__navigation {
    top: 10px;
}

.react-datepicker__time-list {
    overflow-y: overlay !important;
    height: 167px !important;
}
.react-datepicker__time-box {
    width: unset !important;
    padding-top: 0.5rem;
}

.react-datepicker__time-list::-webkit-scrollbar {
    background-color: transparent;
}

.react-datepicker__time-list::-webkit-scrollbar-thumb {
    height: 40px;
    border: 4px solid rgba(255, 255, 255, 0);
    background-clip: padding-box;
    background-color: rgba(255, 255, 255, 0.2);
    -webkit-border-radius: 7px;
    -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}

.react-datepicker__time-list::-webkit-scrollbar-button {
    display: none;
    width: 0;
    height: 0;
}

.react-datepicker__time-list::-webkit-scrollbar-corner {
    background-color: transparent;
}

.react-datepicker__time-list-item {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    display: grid;
    align-items: center;
    justify-content: center;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    font-weight: 600;
    --tw-text-opacity: 1;
    color: rgb(148 163 184 / var(--tw-text-opacity));
}
.react-datepicker__time-list-item:hover {
    background-color: transparent !important;
    --tw-text-opacity: 1;
    color: rgb(55 65 81 / var(--tw-text-opacity));
}
:is(.dark .react-datepicker__time-list-item:hover) {
    --tw-text-opacity: 1;
    color: rgb(226 232 240 / var(--tw-text-opacity));
}

.react-datepicker__time-list-item--selected {
    background-color: transparent !important;
    --tw-text-opacity: 1 !important;
    color: rgb(59 130 246 / var(--tw-text-opacity)) !important;
}

.react-datepicker-time__header {
    font-weight: 500;
    text-transform: capitalize;
    --tw-text-opacity: 1;
    color: rgb(55 65 81 / var(--tw-text-opacity));
}

:is(.dark .react-datepicker-time__header) {
    --tw-text-opacity: 1;
    color: rgb(226 232 240 / var(--tw-text-opacity));
}

.react-datepicker__day--disabled {
    pointer-events: none;
    font-weight: 500 !important;
    --tw-text-opacity: 1 !important;
    color: rgb(156 163 175 / var(--tw-text-opacity)) !important;
}

:is(.dark .react-datepicker__day--disabled) {
    --tw-text-opacity: 1;
    color: rgb(100 116 139 / var(--tw-text-opacity));
}

.react-datepicker__day--selected {
    background-color: transparent !important;
    --tw-text-opacity: 1 !important;
    color: rgb(59 130 246 / var(--tw-text-opacity)) !important;
}

.react-datepicker__day--keyboard-selected {
    background-color: transparent !important;
    --tw-text-opacity: 1 !important;
    color: rgb(59 130 246 / var(--tw-text-opacity)) !important;
}

.react-datepicker__day {
    font-weight: 600;
    --tw-text-opacity: 1;
    color: rgb(17 24 39 / var(--tw-text-opacity));
}

:is(.dark .react-datepicker__day) {
    --tw-text-opacity: 1;
    color: rgb(203 213 225 / var(--tw-text-opacity));
}

.react-datepicker__day:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

:is(.dark .react-datepicker__day:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(51 65 85 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}

.react-datepicker__current-month {
    font-weight: 500;
    --tw-text-opacity: 1;
    color: rgb(55 65 81 / var(--tw-text-opacity));
}

:is(.dark .react-datepicker__current-month) {
    --tw-text-opacity: 1;
    color: rgb(226 232 240 / var(--tw-text-opacity));
}

.react-datepicker__day-name {
    --tw-text-opacity: 1;
    color: rgb(107 114 128 / var(--tw-text-opacity));
}

:is(.dark .react-datepicker__day-name) {
    --tw-text-opacity: 1;
    color: rgb(100 116 139 / var(--tw-text-opacity));
}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before {
    --tw-border-opacity: 1;
    border-bottom-color: rgb(229 231 235 / var(--tw-border-opacity));
}

:is(.dark .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle)::before {
    --tw-border-opacity: 1 !important;
    border-bottom-color: rgb(51 65 85 / var(--tw-border-opacity)) !important;
}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::after {
    --tw-border-opacity: 1;
    border-bottom-color: rgb(229 231 235 / var(--tw-border-opacity));
}

:is(.dark .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle)::after {
    --tw-border-opacity: 1 !important;
    border-bottom-color: rgb(15 23 42 / var(--tw-border-opacity)) !important;
}

.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before {
    --tw-border-opacity: 1;
    border-top-color: rgb(229 231 235 / var(--tw-border-opacity));
}

:is(.dark .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle)::before {
    --tw-border-opacity: 1 !important;
    border-top-color: rgb(51 65 85 / var(--tw-border-opacity)) !important;
}

.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::after {
    --tw-border-opacity: 1;
    border-top-color: rgb(229 231 235 / var(--tw-border-opacity));
}

:is(.dark .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle)::after {
    --tw-border-opacity: 1 !important;
    border-top-color: rgb(15 23 42 / var(--tw-border-opacity)) !important;
}

